import store from '@/store';

import globalToast from '@/composables/common/globalToast';

const dtrGenerator = () => {
    const { showGlobalToast } = globalToast();

    const fetchDTRDetails = (dprDetailsObj: any) => {
        store.dispatch('r_dtrDetails', []);

        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        const { dtrcurrentdronenumber } = dprDetailsObj;

        let requestObj = {
            type: 'reports',
            request: 'getflightsfordtr',
            validateusername: username,
            validatepassword: password,
            dtrcurrentdronenumber
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                let flightslist = responseObj.flightslist;

                store.dispatch('r_dtrDetails', flightslist);
            } else {
                showGlobalToast('No Flights For the Day');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    const generateDTR = (dtrDetailsObj: any, checkedFlightIDs: any) => {
        constructDTRTable(dtrDetailsObj, checkedFlightIDs);
    }

    const constructDTRTable = (dtrDetailsObj: any, checkedFlightIDs: any) => {
        const { dtrcurrentdronenumber, dtrharddiskno, dtrsentthrough, dtrsenton, dtrpreparedby, dtrcheckedby } = dtrDetailsObj;

        const { noofvillages, noofhamlets } = getCalculationData(checkedFlightIDs);

        let tableHTML = '<table border="1" id="dtrtable" class="dtrtable" style="width: 100%">';
        tableHTML += '<tr><td colspan="7" style="text-align: left;">To <br/> The Director, KGDC</td></tr>';
        tableHTML += '<tr><td colspan="7" style="text-align: center;">';
        tableHTML += '<b>Sub: Submission of Drone Flying Raw Data with the follwing details</b></td></tr>';
        tableHTML += '<tr><td colspan="7"></tr>';

        tableHTML += '<tr><td colspan="2">Number of Flights</td><td>' + (checkedFlightIDs.length).toString() + '</td>';
        tableHTML += '<td>Harddisk No</td><td>' + dtrharddiskno + '</td>';
        tableHTML += '<td>DroneNumber</td><td>' + dtrcurrentdronenumber + '</td></tr>';

        tableHTML += '<tr><td colspan="2">Number of Villages</td><td>' + noofvillages + '</td>';
        tableHTML += '<td>Sent Through</td><td>' + dtrsentthrough + '</td>';
        tableHTML += '<td>Prepared By</td><td>' + dtrpreparedby + '</td></tr>';

        tableHTML += '<tr><td colspan="2">Number of Hamlets</td><td>' + noofhamlets + '</td>';
        tableHTML += '<td>Sent On</td><td>' + dtrsenton + '</td>';
        tableHTML += '<td>Checked By</td><td>' + dtrcheckedby + '</td></tr>';

        tableHTML += '<tr><td colspan="7"><br/></tr>';

        tableHTML += appendRowsHTML(checkedFlightIDs);

        tableHTML += '</table>';

        tableHTML = tableHTML.replaceAll('null', '');

        generateSummaryPDF(tableHTML);
    }

    const appendRowsHTML = (checkedFlightIDs: any) => {
        const dtrDetails = store.getters.r_dtrDetails;

        let rowHTML = '<tr>';
        rowHTML += '<td><b>SNO</b></td><td><b>FLIGHT DETAILS</b></td><td><b>LOCATION DETAILS</b></td>';
        rowHTML += '<td><b>LGD CODES</b></td><td><b>HAMLETS</b></td>';
        rowHTML += '<td><b>PARTICULARS</b></td><td><b>REMARKS</b></td>';
 
        rowHTML += '</tr>';

        let filteredFlights = dtrDetails.filter((flight: any) => {
            let flightid = flight['flightid'];
            let category = flight['flightcategory'];

            let cond1 = checkedFlightIDs.includes(flightid);
            let cond2 = category != 'SVAMITVA' && category != 'LSMK_SVAMITVA' && category != 'LSMK';
            let condA = cond1 && !cond2;

            return condA;
        });

        console.log(filteredFlights);

        for (let i = 0; i < filteredFlights.length; i++){
            let flightObj = filteredFlights[i];

            let newDate = new Date(flightObj.flightdate);
            const offset = newDate.getTimezoneOffset();
            newDate = new Date(newDate.getTime() - (offset * 60 * 1000));
            let dateFormatted = newDate.toISOString().split('T')[0];

            rowHTML += '<tr>';
            rowHTML += '<td>'+ (i + 1).toString() +'</td>';

            rowHTML += '<td>';
            rowHTML += '<i>Flight Date:</i> ' + dateFormatted + '<br>';
            rowHTML += '<i>Flight ID:</i> '   + flightObj.flightid + '<br>';
            rowHTML += '<i>Flight Category:</i> ' + flightObj.flightcategory + '<br>';
            rowHTML += '<i>Fresh / Refly:</i> ' + flightObj.freshrefly + '<br>';
            rowHTML += '<i>Area:</i> ' + flightObj.area + ' sq.km.';
            rowHTML += '</td>';

            rowHTML += '<td>';
            rowHTML += '<i>District:</i> ' + flightObj.district + '<br>';
            rowHTML += '<i>Taluk:</i> '   + flightObj.taluk + '<br>';
            rowHTML += '<i>Gram Panchayat:</i> ' + flightObj.grampanchayat + '<br>';
            rowHTML += '<i>Villages:</i> ' + flightObj.villages;
            rowHTML += '</td>';

            rowHTML += '<td>' + flightObj.lgdcodes + '</td>';
            rowHTML += '<td>' + flightObj.hamlets + '</td>';

            rowHTML += '<td>';
            rowHTML += '<i>Villages Count:</i> ' + flightObj.villagescount + '<br>';
            rowHTML += '<i>Hamlets Count:</i> '   + flightObj.hamletscount + '<br>';
            rowHTML += '<i>Raw Images:</i> ' + flightObj.rawimagescount + '<br>';
            rowHTML += '<i>Geotagged:</i> ' + flightObj.geotagged + '<br>';
            rowHTML += '<i>Total Files:</i> ' + flightObj.totalfiles + '<br>';
            rowHTML += '<i>Folder Size:</i> ' + flightObj.foldersizegb + ' GB';
            rowHTML += '</td>';

            rowHTML += '<td>' + flightObj.remarks + '</td>';
            rowHTML += '</tr>';
        }

        return rowHTML;
    }

    const getCalculationData = (checkedFlightIDs: any) => {
        let noofvillages = 0;
        let noofhamlets = 0;

        const dtrDetails = store.getters.r_dtrDetails;

        for (let i = 0; i < dtrDetails.length; i++){
            let dtrObj = dtrDetails[i];
            let flightIDDTR = dtrObj['flightid'];
            if(checkedFlightIDs.includes(flightIDDTR)){
                noofvillages += isFinite(parseInt(dtrObj['villagescount'])) ? parseInt(dtrObj['villagescount']) : 0;
                noofhamlets += isFinite(parseInt(dtrObj['hamletscount'])) ? parseInt(dtrObj['hamletscount']) : 0;
            }
        }

        return {
            noofvillages, noofhamlets
        }
    }

    const generateSummaryPDF = (tableHTML: any) => {
        let w = window.open()!;

        w.document.head.innerHTML = `
          <title>Data Transfer Register</title>
          
          <style>
            table {
                border-collapse: collapse;
            }
            td {
                font-size: 10px;
                text-align: center; 
                vertical-align: middle;
            }
          </style>
        `;

        w.document.body.innerHTML = tableHTML;
        w.document.close();
            
        w.focus();
        w.print();
    }

    return { fetchDTRDetails, generateDTR }
}

export default dtrGenerator;