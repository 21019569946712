import KML from 'ol/format/KML';

import store from "@/store";

const flightLineDownloader = () => {
    const downloadCurrentFlightLineAsKML = () => {
        const fi_flightFeat = store.getters.fi_flightFeat;
        const fi_flightInfo = store.getters.fi_flightInfo;
        // console.log(fi_flightFeat);

        let fi_flightFeat_with_props = fi_flightFeat.setProperties(fi_flightInfo);
        let featKML = new KML().writeFeatures([fi_flightFeat_with_props], {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        // console.log(featKML);

        let a = document.createElement("a");
        a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(featKML);
        a.download = `${fi_flightInfo.flightid}.kml`;
        a.click();
    }

    return { 
        downloadCurrentFlightLineAsKML
    }
}

export default flightLineDownloader;