import store from '@/store';

import globalToast from '@/composables/common/globalToast';

const dprGenerator = () => {
    const { showGlobalToast } = globalToast();

    const generateDPR = (dprDetailsObj: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        const { dprcurrentdronenumber, dprsummarydate } = dprDetailsObj;

        let requestObj = {
            type: 'reports',
            request: 'getflightsfordpr',
            validateusername: username,
            validatepassword: password,
            dprcurrentdronenumber, dprsummarydate
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                let flightslist = responseObj.flightslist;

                constructDPRTable(dprDetailsObj, flightslist);
            } else {
                showGlobalToast('No Flights For the Day');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    const constructDPRTable = (dprDetailsObj: any, flightslist: any) => {
        let { dprcurrentdronenumber, dprsummarydate, dprdistrict, dprtaluk,
            dprpilotname, dprfieldassistant, dprcampingarea, dprcontactno, dprremarks } = dprDetailsObj;

        let calcsData = getCalculationData(flightslist);

        let tableHTML = '<table border="1">';

        // 1st Row
        tableHTML += '<tr><td colspan="8"><span style="font-weight:bold;font-size:x-large;">Consolidated Drone Flying Report</span></td>';
        tableHTML += '<td colspan="1"><b>Date</b></td><td colspan="2"><i>' + dprsummarydate + '</i></td></tr>';

        // 2nd Row
        tableHTML += '<tr><td colspan="2"><b>Drone Number</b></td><td colspan="4">' + dprcurrentdronenumber + '</td>';
        tableHTML += '<td colspan="2"><b>Contact Number</b></td><td colspan="3">' + dprcontactno + '</td></tr>';

        // 3rd Row
        tableHTML += '<tr><td colspan="2"><b>Pilot Name</b></td><td colspan="4">' + dprpilotname + '</td>';
        tableHTML += '<td colspan="2"><b>Field Assistant</b></td><td colspan="3">' + dprfieldassistant + '</td></tr>';

        // 4th Row
        tableHTML += '<tr><td colspan="2"><b>District / Taluk</b></td><td colspan="4">' + dprdistrict + ' / ' + dprtaluk + '</td>';
        tableHTML += '<td colspan="2"><b>Camping Area</b></td><td colspan="3">' + dprcampingarea + '</td></tr>';

        // 5th Row
        tableHTML += '<tr><td colspan="11" style="height: 20px;"></td></tr>';

        // Flights Details Section 1, 2, 3, 4
        tableHTML += appendRowsHTML(flightslist, 1, 10);
        tableHTML += appendRowsHTML(flightslist, 11, 19);
        tableHTML += appendRowsHTML(flightslist, 20, 29);
        tableHTML += appendRowsHTML(flightslist, 30, 36);

        // 1st Row
        tableHTML += '<tr><td colspan="2"><b>TOTAL FLIGHTS</b></td><td colspan="2">' + calcsData['TOTAL_FLIGHTS'] + '</td>';
        tableHTML += '<td colspan="2"><b>UNSUCCESSFUL FLIGHTS</b></td><td colspan="2">' + calcsData['UNSUCCESSFUL_FLIGHTS'] + '</td>';
        tableHTML += '<td colspan="2"><b>FLIGHT_DURATION</b></td><td colspan="1" id="tablecontactno">' + calcsData['FLIGHT_DURATION'] + '</td></tr>';

        // 2nd Row
        tableHTML += '<tr><td colspan="2"><b>AREA_COVERED(SQ.KM.)</b></td><td colspan="2">' + calcsData['AREA_COVERED(SQ.KM.)'] + '</td>';
        tableHTML += '<td colspan="2"><b>NO_OF_VILLAGES</b></td><td colspan="2">' + calcsData['NO_OF_VILLAGES'] + '</td>';
        tableHTML += '<td colspan="2"><b>NO_OF_HAMLETS</b></td><td colspan="1">' + calcsData['NO_OF_HAMLETS'] + '</td></tr>';

        // 3rd Row
        tableHTML += '<tr><td colspan="11" style="height: 20px;"></td></tr>';

        // 4th Row
        tableHTML += '<tr><td colspan="3" style="height: 50px;">REMARKS / PROBLEMS FACED</td>';
        tableHTML += '<td colspan="8" style="height: 50px;">' + dprremarks + '</td></tr>';

        tableHTML += '</table>';

        generateSummaryPDF(tableHTML);
    }

    const appendRowsHTML = (flightslist: any, start: any, end: any) => {
        let columnOrder: any;
        columnOrder = {
            flightid: 1,
            flightcategory: 2,
            flightdate: 3,
            takeofftime: 4,
            landingtime: 5,
            duration: 6,
            trainingflight: 7,
            freshrefly: 8,
            area: 9,
            uavheight: 10,
            pilotname: 11,
            fieldassistant: 12,
            campingarea: 13,
            district: 14,
            taluk: 15,
            grampanchayat: 16,
            villages: 17,
            lgdcodes: 18,
            hamlets: 19,
            villagescount: 20,
            hamletscount: 21,
            temperature: 22,
            windspeed: 23,
            overlap: 24,
            basegpsid: 25,
            ibasefilename: 26,
            softwareversion: 27,
            rawimagescount: 28,
            geotagged: 29,
            avggsd: 30,
            batteryno: 31,
            flylogno: 32,
            totalfiles: 33,
            foldersizegb: 34,
            username: 35,
            remarks: 36
        };

        let rowHTML = '<tr>';
        rowHTML += '<td><b>FLY</b></td>';

        for (let i = start; i <= end; i++){
            let columnName = Object.keys(columnOrder).find((key: string) => columnOrder[key] === i);
            let columnUpper = columnName?.toUpperCase();

            if(i == 19){    //  Hamlets
                rowHTML += '<td colspan="2"><b>' + columnUpper + '</b></td>';
            } else if(i == 36){     //  Remarks
                rowHTML += '<td colspan="4"><b>' + columnUpper + '</b></td>';
            } else {
                rowHTML += '<td><b>' + columnUpper + '</b></td>';
            }
        }

        rowHTML += '</tr>';

        for (let i = 0; i < flightslist.length; i++){
            let flightObj = flightslist[i];
            rowHTML += '<tr>';
            rowHTML += '<td>'+ flightObj['flightcount'] +'</td>';

            for (let i = start; i <= end; i++){
                let columnName = <string>Object.keys(columnOrder).find(key => columnOrder[key] === i);
                let cellValue = flightObj[columnName];
                // console.log(cellValue);

                // Formatted Date Change Cell Value
                if(i == 3){
                    let newDate = new Date(cellValue);
                    // console.log(newDate);
                    const offset = newDate.getTimezoneOffset();
                    newDate = new Date(newDate.getTime() - (offset * 60 * 1000));
                    cellValue = newDate.toISOString().split('T')[0];
                }
                
                if(i == 19){    //  Hamlets
                    rowHTML += '<td colspan="2">' + cellValue + '</td>';
                } else if(i == 36){ //  Remarks
                    rowHTML += '<td colspan="4">' + cellValue + '</td>';
                } else {
                    rowHTML += '<td>' + cellValue + '</td>';
                }
            }
            rowHTML += '</tr>';
        }

        rowHTML += '<tr><td colspan="11" style="height: 20px;"></td></tr>';

        return rowHTML;
    }

    const getCalculationData = (flightslist: any) => {
        console.log(flightslist);
        let totalflights = flightslist.length;
        let unsuccessfulflights = 0;
        let flightduration = 0;
        let areacovered = 0;
        let noofvillages = 0;
        let noofhamlets = 0;

        for (let i = 0; i < flightslist.length; i++){
            let flightObj = flightslist[i];

            let category = flightObj['flightcategory'];
            
            if(category != 'SVAMITVA' && category != 'LSMK_SVAMITVA' && category != 'LSMK'){
                unsuccessfulflights++;
            }

            let flightdurationStr = flightObj['duration'];
            let flightdurationStrParts = flightdurationStr.split(':');
            flightduration += parseInt(flightdurationStrParts[0]) * 60 + parseInt(flightdurationStrParts[1]);

            areacovered += isFinite(parseFloat(flightObj['area'])) ? parseFloat(flightObj['area']) : 0;
            noofvillages += isFinite(parseInt(flightObj['villagescount'])) ? parseInt(flightObj['villagescount']) : 0;
            noofhamlets += isFinite(parseInt(flightObj['hamletscount'])) ? parseInt(flightObj['hamletscount']) : 0;
        }

        let calcsData = {
            'TOTAL_FLIGHTS': totalflights,
            'UNSUCCESSFUL_FLIGHTS': unsuccessfulflights,
            'FLIGHT_DURATION': flightduration,
            'AREA_COVERED(SQ.KM.)': areacovered.toFixed(3),
            'NO_OF_VILLAGES': noofvillages,
            'NO_OF_HAMLETS': noofhamlets
        };

        return calcsData;
    }

    const generateSummaryPDF = (tableHTML: any) => {
        let w = window.open()!;

        w.document.head.innerHTML = `
          <title>Drone Flying - DPR</title>
          <style>
            table {
              border-collapse: collapse;
            }
            
            td {
              font-size: 10px;
              text-align: center; 
              vertical-align: middle;
            }
          </style>
        `;

        w.document.body.innerHTML = tableHTML;
        w.document.close();
            
        w.focus();
        w.print();
    }

    return { generateDPR }
}

export default dprGenerator;