
import store from '@/store';
import { computed, defineComponent, ref } from 'vue';
import './FlightsManager.scss';

import globalToast from '@/composables/common/globalToast';
import dprGenerator from '@/composables/reports/dprGenerator';
import dtrGenerator from '@/composables/reports/dtrGenerator';
import flightLineDownloader from '@/composables/flights/flightLineDownloader';

export default defineComponent({
    setup() {
        const { showGlobalToast } = globalToast();
        const { generateDPR } = dprGenerator();
        const { fetchDTRDetails, generateDTR } = dtrGenerator();
        const { downloadCurrentFlightLineAsKML } = flightLineDownloader();

        const flightsManageMode = ref('flightsinfo');
        const flightInfoEmpty = computed(() => Object.keys(store.getters.fi_flightInfo).length == 0);
        const fi_info_Obj_comp = computed(() => store.getters.fi_flightInfo);

        const inputTypes = <any>{
            'text': [
                'taluk', 'hamlets', 'overlap', 'remarks', 'flylogno', 'lgdcodes', 'villages',
                'basegpsid', 'batteryno', 'pilotname', 'campingarea', 'temperature',
                'grampanchayat', 'ibasefilename', 'fieldassistant', 'softwareversion'
            ],
            'number': [
                'area', 'avggsd', 'geotagged', 'uavheight', 'windspeed', 'totalfiles', 'foldersizegb',
                'hamletscount', 'villagescount', 'rawimagescount'
            ],
            'date': [
                'flightdate', 'markingstartdate'
            ]
        };

        const returnInputType = (col: any) => {
            let inputType = Object.keys(inputTypes)
            .filter((key: any) => {
                let foundCol = false;
                let colArry = inputTypes[key];
                for (let i = 0; i < colArry.length; i++){
                    if(colArry[i] == col) foundCol = true;
                }
                return foundCol;
            });
            return inputType;
        }

        const fi_flightInfo_mod = computed(() => {
            let infoSets = <any>[];
            let fi_flightInfo = store.getters.fi_flightInfo;
            // console.log(ai_currentabadiInfo);

            let filterCols = ['servertime', 'uniqueflightnumber', 'dronenumber', 'deleted'];

            let fi_currentflightInfo_arry = Object.keys(fi_flightInfo)
            .filter((key) => filterCols.indexOf(key) == -1)
            .map((key) => [key, fi_flightInfo[key]]);
            
            for (let i = 0; i < fi_currentflightInfo_arry.length; i = i + 3){
                let el1 = fi_currentflightInfo_arry[i] != undefined ? fi_currentflightInfo_arry[i] : '';
                let el2 = fi_currentflightInfo_arry[i + 1] != undefined ? fi_currentflightInfo_arry[i + 1] : '';
                let el3 = fi_currentflightInfo_arry[i + 2] != undefined ? fi_currentflightInfo_arry[i + 2] : '';

                infoSets.push([el1, el2, el3]);
            }

            // console.log(infoSets);
            return infoSets;
        });

        const g_districtsList = computed(() => store.getters.g_districtsList);
        const droneNumbersList = computed(() => store.getters.g_droneNumbers);

        const dprcurrentdronenumber = ref('');
        const dprsummarydate = ref('');
        const dprdistrict = ref('');
        const dprtaluk = ref('');
        const dprpilotname = ref('');
        const dprfieldassistant = ref('');
        const dprcampingarea = ref('');
        const dprcontactno = ref('');
        const dprremarks = ref('');

        const callGenerateDPR = () => {
            if(dprcurrentdronenumber.value == '' || dprsummarydate.value == ''){
                showGlobalToast('Select DroneNumber & Date');
                return 0;
            }

            let dprDetailsObj = {
                dprcurrentdronenumber: dprcurrentdronenumber.value,
                dprsummarydate: dprsummarydate.value,
                dprdistrict: dprdistrict.value,
                dprtaluk: dprtaluk.value,
                dprpilotname: dprpilotname.value,
                dprfieldassistant: dprfieldassistant.value,
                dprcampingarea: dprcampingarea.value,
                dprcontactno: dprcontactno.value,
                dprremarks: dprremarks.value
            }

            generateDPR(dprDetailsObj);
        }

        const dtrDetails = computed(() => store.getters.r_dtrDetails);

        const dtrcurrentdronenumber = ref('');
        const dtrharddiskno = ref('');
        const dtrsentthrough = ref('');
        const dtrsenton = ref('');
        const dtrpreparedby = ref('');
        const dtrcheckedby = ref('');

        const callfetchDTRDetails = () => {
            if(dtrcurrentdronenumber.value == ''){
                showGlobalToast('Select DroneNumber');
                return 0;
            }

            let dprDetailsObj = {
                dtrcurrentdronenumber: dtrcurrentdronenumber.value,
            }

            fetchDTRDetails(dprDetailsObj);
        }

        const callgenerateDTR = () => {
            let checkedFlightIDs = getCheckedFlightIDs();
            console.log(checkedFlightIDs);
            let dtrDetailsObj = {
                dtrcurrentdronenumber: dtrcurrentdronenumber.value,
                dtrharddiskno: dtrharddiskno.value,
                dtrsentthrough: dtrsentthrough.value,
                dtrsenton: dtrsenton.value,
                dtrpreparedby: dtrpreparedby.value,
                dtrcheckedby: dtrcheckedby.value
            }

            generateDTR(dtrDetailsObj, checkedFlightIDs);
        }

        const getCheckedFlightIDs = () => {
            let checkedFlightIDs = [];
            let checkboxes = document.querySelectorAll("input[class='dtrchecks'][type='checkbox']:checked");
            // console.log(checkboxes);
            for(var i = 0; i < checkboxes.length; i++) {
                let chkbx = checkboxes[i];
                checkedFlightIDs.push(chkbx.getAttribute('flightid'))
            }

            return checkedFlightIDs;
        }

        const callDownloadFlightline = () => {
            downloadCurrentFlightLineAsKML();
        }

        const callFlightlineDeletion = () => {
            let dr_changeRequestObj = {
                tochange: 'flightline',
                changetype: 'delete',
                uniqueid: fi_info_Obj_comp.value.flightid
            };

            store.dispatch('dr_showReasonBox', true);
            store.dispatch('dr_changeRequestObj', dr_changeRequestObj);
        }

        const flightsinfotable = ref();

        const callRequestDataChanges = () => {
            let els = Array.from(flightsinfotable.value.getElementsByClassName('changehelperclass'));

            const changeRequestedValues = els.map((el: any) => {
                el.disabled = true;

                let column = el.getAttribute('column');
                let oldvalue = el.getAttribute('oldvalue');
                let newvalue = el.value;
                if(oldvalue != el.value){
                    return { column, oldvalue, newvalue }
                }
            }).filter(el => el != undefined);

            console.log(changeRequestedValues);

            if(changeRequestedValues.length == 0){
                showGlobalToast('No Values To Update');
                return 0;
            }

            let changeRequestObj = {
                tochange: 'flightline',
                changetype: 'update',
                uniqueid: fi_info_Obj_comp.value.flightid,
                changesreqd: JSON.stringify(changeRequestedValues)
            };

            store.dispatch('dr_changeRequestObj', changeRequestObj);
            store.dispatch('dr_showReasonBox', true);
        }

        const enableCell = (e: any) => {
            e.target.parentNode.firstElementChild.disabled = !e.target.parentNode.firstElementChild.disabled;
            e.target.parentNode.firstElementChild.focus();
        }

        const handleStyleColor = (e: any) => {
            let el = e.target;
            let oldvalue = el.getAttribute('oldvalue');

            if(oldvalue == el.value){
                el.style.color = 'black';
            } else {
                el.style.color = 'red';
            }
        }

        return {
            flightsManageMode, flightInfoEmpty,
            fi_flightInfo_mod,
            g_districtsList, droneNumbersList,
            dprcurrentdronenumber, dprsummarydate, dprdistrict, dprtaluk,
            dprpilotname, dprfieldassistant, dprcampingarea, dprcontactno, dprremarks, callGenerateDPR,
            dtrcurrentdronenumber, dtrharddiskno, dtrsentthrough, dtrsenton, dtrpreparedby, dtrcheckedby,
            dtrDetails, callfetchDTRDetails, callgenerateDTR, 
            callDownloadFlightline, callFlightlineDeletion,
            returnInputType, flightsinfotable, enableCell, handleStyleColor,
            callRequestDataChanges
        }
    },
})
